<template>

  <div class="d-center-content fill-height d-article" :class="{'mr-7': $vuetify.breakpoint.smAndDown}">

    <div class="d-skew-mother" v-if="headline != 'Newsletter'">
      <div class="d-skew-left px-10" v-if="headline">
        <span v-if="headline == 'Komm-ins-Team'">{{article.title}}!</span>
        <span v-else>{{headline}}</span>
      </div>
      <div class="d-skew-center"></div>
      <div class="d-skew-right"></div>
      <div class="d-skew-abdecker "></div>
      <div class="d-skew-abdecker-white "></div>
    </div>
    <div class="d-content-content">

        <!-- <pre> {{ article }} </pre> -->
        <v-container class="us-transition py-0 pl-0 pr-4" style="overflow-y:auto;">
            <div v-html="article.html"></div>
        </v-container>

    </div>

    <FormJob :job="headline" v-if="headline == 'Jobs Fahrer' ||  headline == 'Jobs Office' || headline == 'Komm ins Team' || headline == 'Komm ins Team Busfahrer'"></FormJob>


  </div>
</template>

<script>
import { mapState } from "vuex"
import FormJob from "@/components/Forms/Job"
export default {
    components: {
        FormJob
    },
    computed: {
        ...mapState(["ContentStore"])
    },
    data() {
        return {
            headline:"Willkommen",
            article: {
                id_web_content: null,
                title: null,
                alias: null,
                html: null
            }
        }
    },
    watch: {
        $route(to, from) {
            this.args = core.getPathArgs(["id"])
            this.loadArticle()
        }
    },
    mounted() {
        this.args = core.getPathArgs(["id"])
        if (this.args.id === undefined && this.ContentStore.find(item => item.title == "Willkommen") !== undefined) {
            this.args.id = this.ContentStore.find(item => item.title == "Willkommen").id_web_content
        }
        if (this.ContentStore.find(item => item.id_web_content == this.args.id || item.alias == this.args.id) === undefined) {
            this.article = {
                id_web_content: 0,
                title: "",
                alias: "",
                html: "Der Inhalt dieser Seite wurde leider nicht gefunden. Klicken Sie <a href=\"#/\">hier</a>, um zurück zur Startseite zu gelangen."
            }
        } else {
            this.loadArticle()
        }
        core.on("content-finished", this.loadArticle)
    },
    methods: {
        loadArticle() {


            if (this.args.id === undefined) {
                this.args.id = this.ContentStore.find(item => item.title == "Willkommen").id_web_content
            }


            this.article = this.ContentStore.find(item => item.id_web_content == this.args.id || item.alias == this.args.id)
            this.headline = this.article.title == 'Reisebuero' ? 'Reisebüro' : this.article.title

        }
    }
}
</script>

<style lang="scss">
    .main-content-article {
        height: 100%;
        overflow: auto;
        padding: 0px 10px 20px 0px;
    }
    .us-scroll-versatz{
        padding-right:20px;
    }
    #us-all-Journey-cart1 { height:calc(100% - 0px) ; overflow: hidden; }
    .us-grid-two-top, .us-grid-two-bottom {height:10px;}
    .us-pic-container-category-right{
        width:100%;
        height:calc(100% - 20px) ;
        // background-image:url('../assets/image/kpic1.jpg');
        background-repeat: no-repeat; background-position: 25% 50%; background-size: cover;
        position:relative;
    }


    .d-article img {
        max-width:100% !important;
        height:auto !important;
        padding-right:20px !important;
    }
    .d-article a {
        text-decoration:none;
    }

    .imageTextResponsive {
        display: flex;
        gap: 12px;
    }
    
    .imgBewerbung {
        display: flex;
        justify-content: center;
        align-content: center;
    }

    @media (min-width: 1264px) {
        .imageTextResponsive {
            flex-direction: row;
        }
        .orderIMG {
            order: 2 !important;
        }
        .orderText {
            order: 1 !important;
        }
        .bewerbungText1 {
            display: block;
        }
        .bewerbungText2 {
            display: none;
        }
    }
    @media (max-width: 1264px) {
        .imageTextResponsive {
            flex-direction: column;
        }
        .orderIMG {
            order: 1 !important;
        }
        .orderText {
            order: 2 !important;
        }
        .bewerbungText1 {
            display: none;
        }
        .bewerbungText2 {
            display: block;
        }
    }
</style>